"use client";

import React, { useState, useEffect, useRef } from "react";
import Link from "next/link";
import dynamic from "next/dynamic"; // Import dynamic for code-splitting
import HeaderSearchBar from "./HeaderSearchBar";

const Header = () => {
  const [showMenu, setShowMenu] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);

  const handleMenuToggle = () => setIsOpen(!isOpen);
  const handleItemClick = () => setIsOpen(false);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const handleClickOutside = (event: { target: any }) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setShowMenu(false);
    }
  };

  const handleScroll = () => {
    setShowMenu(false);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    window.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [menuOpen, setMenuOpen] = useState(false);

  const handleMenuToggleScraper = () => setMenuOpen(!menuOpen);
  const handleItemClickScraper = () => setMenuOpen(false);

  const [menuOpens, setMenuOpens] = useState(false);

  const handleMenuToggleStats = () => setMenuOpens(!menuOpens);
  const handleItemClickStats = () => setMenuOpens(false);

  // const showSearchBarOn = [
  //   "/",
  //   "/b2b",
  //   `/b2b-database/[list]`,
  //   `/b2b-database/[list]/[statelist]`,
  //   `/b2b-database/[list]/[statelist]/[citylist]`,
  // ];

  return (
    <header className="pt-3  h-[60px] sticky top-0 bg-white z-50">
      <nav className="flex justify-between items-center mx-auto max-w-[1880px] w-full ">
        {/* Logo */}
        <div>
          <Link href="/">
            <div className="-mt-2 pl-3">
              <div className="text-[var(--title-color)] text-3xl">
                <span className="gradient-new-text">Biz</span>Dataset
              </div>
              <div className="text-slate-900 text-xs  text-center">
                Find <span className="gradient-new-text">•</span> Connect{" "}
                <span className="gradient-new-text ">•</span> Engage
              </div>
            </div>
          </Link>
        </div>

        {/* Center section with search bar */}
<div className="md:ml-44">
<HeaderSearchBar />
</div>

        {/* Desktop Menu */}
        <div className="hidden md:flex items-center space-x-6">
          {/* Menu items */}

          <ul className="font-medium flex items-center space-x-8 pr-4">

          <li
              className="relative group"
              onMouseEnter={() => setIsOpen(true)}
              onMouseLeave={() => setIsOpen(false)}
            >
                    <Link href="/b2b-list">
                    <div
                className="text-[var(--text-color)] text-[14px] hover:underline underline-offset-4 cursor-pointer"
                onClick={handleMenuToggle}
              >
               B2B Datasets
              </div>
              </Link>
              {/* Dropdown Menu */}
              {/* <div
                className={`absolute left-0 text-[14px] ${
                  isOpen ? "block" : "hidden"
                } bg-white text-black shadow-lg w-48`}
              >
                <ul className="p-2">
                  <li>
                    <Link href="/b2b-list">
                      <div
                        className="block px-4 py-2 hover:underline underline-offset-4"
                        onClick={handleItemClick}
                      >
                        B2B
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link href="/services">
                      <div
                        className="block px-4 py-2 hover:underline underline-offset-4"
                        onClick={handleItemClick}
                      >
                        Services
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link href="/platform">
                      <div
                        className="block px-4 py-2 hover:underline underline-offset-4"
                        onClick={handleItemClick}
                      >
                        Platform
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link href="ucat/email-marketing-database">
                      <div
                        className="block px-4 py-2 hover:underline underline-offset-4"
                        onClick={handleItemClick}
                      >
                        Email Marketing
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link href="ucat/sms-marketing-database">
                      <div
                        className="block px-4 py-2 hover:underline underline-offset-4"
                        onClick={handleItemClick}
                      >
                        SMS Marketing
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link href="ucat/whatsapp-marketing-database">
                      <div
                        className="block px-4 py-2 hover:underline underline-offset-4"
                        onClick={handleItemClick}
                      >
                        WhatsApp Marketing
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link href="ucat/facebook-marketing-database">
                      <div
                        className="block px-4 py-2 hover:underline underline-offset-4"
                        onClick={handleItemClick}
                      >
                        Facebook Marketing
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link href="ucat/linkedin-marketing-database">
                      <div
                        className="block px-4 py-2 hover:underline underline-offset-4"
                        onClick={handleItemClick}
                      >
                        LinkedIn Marketing
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link href="ucat/twitter-marketing-database">
                      <div
                        className="block px-4 py-2 hover:underline underline-offset-4"
                        onClick={handleItemClick}
                      >
                        Twitter Marketing
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link href="ucat/instagram-marketing-database">
                      <div
                        className="block px-4 py-2 hover:underline underline-offset-4"
                        onClick={handleItemClick}
                      >
                        Instagram Marketing
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link href="ucat/snapchat-marketing-database">
                      <div
                        className="block px-4 py-2 hover:underline underline-offset-4"
                        onClick={handleItemClick}
                      >
                        Snapchat Marketing
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link href="ucat/youtube-marketing-database">
                      <div
                        className="block px-4 py-2 hover:underline underline-offset-4"
                        onClick={handleItemClick}
                      >
                        YouTube Marketing
                      </div>
                    </Link>
                  </li>
                </ul>
              </div> */}
            </li>


            <li
              className="relative group"
              onMouseEnter={() => setMenuOpens(true)}
              onMouseLeave={() => setMenuOpens(false)}
            >
                                  <Link href="/stats">
              <div
                className="text-[var(--text-color)] text-[14px] hover:underline underline-offset-4 cursor-pointer"
                onClick={handleMenuToggleStats}
              >
                Social Media Stats
              </div>
              </Link>
              {/* Dropdown Menu */}
              {/* <div
                className={`absolute left-0 text-[14px] ${
                  menuOpens ? "block" : "hidden"
                } bg-white text-black shadow-lg w-48`}
              >
                <ul className="p-2">
                  <li>
                    <Link href="/stats">
                      <div
                        className="block px-4 py-2 hover:underline underline-offset-4"
                        onClick={handleItemClickStats}
                      >
                        Social Media Stats
                      </div>
                    </Link>
                  </li>
                </ul>
              </div> */}
            </li>

            {/* <li
              className="relative group"
              onMouseEnter={() => setMenuOpen(true)}
              onMouseLeave={() => setMenuOpen(false)}
            >
              <div
                className="text-[var(--text-color)] text-[14px] hover:underline underline-offset-4 cursor-pointer"
                onClick={handleMenuToggleScraper}
              >
                Scrapers
              </div>
              <div
                className={`absolute left-0 text-[14px] ${
                  menuOpen ? "block" : "hidden"
                } bg-white text-black shadow-lg w-48`}
              >
                <ul className="p-2">
                  <li>
                    <Link href="/scraper/google-maps">
                      <div
                        className="block px-4 py-2 hover:underline underline-offset-4"
                        onClick={handleItemClickScraper}
                      >
                        Google Maps
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
            </li> */}



            <li className="group relative">
              <Link href="/contact-us">
                <div className="text-[var(--text-color)] text-[14px] hover:underline underline-offset-4">
                  Contact Us
                </div>
              </Link>
            </li>
          </ul>
        </div>

        {/* Hamburger menu for mobile */}
        <div className="md:hidden flex items-center relative pr-3">
          {/* <button className="text-[var(--text-color)]" onClick={toggleMenu}>
            <svg
              className="w-6 h-6"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16m-7 6h7"
              ></path>
            </svg>
          </button> */}

          {/* Hamburger menu content */}
          <div className="md:hidden flex items-center relative pr-3">
            {/* Hamburger / Close Button */}
            <button
              className="text-[var(--text-color)]"
              onClick={toggleMenu}
              aria-label={showMenu ? "Close menu" : "Open menu"} // Add an accessible name
            >
              {showMenu ? (
                // Close Icon (X)
                <svg
                  className="w-6 h-6"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              ) : (
                // Hamburger Icon
                <svg
                  className="w-6 h-6"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16m-7 6h7"
                  />
                </svg>
              )}
            </button>

            {/* Full-Screen Menu Overlay */}
            <div
              className={`fixed left-0 w-full h-full bg-white p-4 shadow-lg ${
                showMenu ? "top-14 opacity-100" : "-top-full opacity-0"
              } z-50 transition-all duration-300 ease-in-out`}
            >
              <ul className="font-medium grid grid-cols-1 gap-4 text-gray-800">
                {[
                  { href: "/b2b-list", label: "B2B Datasets" },
                  // { href: "/services", label: "Services" },
                  // { href: "/platform", label: "Platform" },
                  // { href: "/email-list", label: "Email List" },
                  // { href: "/sms-marketing-database", label: "SMS Marketing" },
                  // {
                  //   href: "/whatsapp-marketing-database",
                  //   label: "WhatsApp Marketing",
                  // },
                  // {
                  //   href: "/facebook-marketing-database",
                  //   label: "Facebook Marketing",
                  // },
                  // {
                  //   href: "/linkedin-marketing-database",
                  //   label: "LinkedIn Marketing",
                  // },
                  // {
                  //   href: "/twitter-marketing-database",
                  //   label: "Twitter Marketing",
                  // },
                  // {
                  //   href: "/instagram-marketing-database",
                  //   label: "Instagram Marketing",
                  // },
                  // {
                  //   href: "/snapchat-marketing-database",
                  //   label: "Snapchat Marketing",
                  // },
                  // {
                  //   href: "/youtube-marketing-database",
                  //   label: "YouTube Marketing",
                  // },
                  // { href: "/scraper/google-maps", label: "Google Maps" },
                  { href: "/stats", label: "Social Media Stats" },
                  { href: "/contact-us", label: "Contact Us" }
                ].map((item, index) => (
                  <li key={index} className="group relative">
                    <Link href={item.href}>
                      <div
                        className="block px-4 py-2 text-[14px] hover:underline underline-offset-4 hover:text-blue-600 transition duration-300 ease-in-out"
                        onClick={toggleMenu}
                      >
                        {item.label}
                      </div>
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
